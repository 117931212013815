<template>
	<div>
		<div class="column column-900">
			<h4>Welkom in ons gezellige vakantiehuisje in het hart van Burgh-Haamstede!</h4>
			<p>
				Geniet van de levendige sfeer op de gezellige terrasjes en de vele restaurants om de hoek. Vanuit het
				huisje sta je binnen een minuut in het centrum, maar je kunt ook op korte loopafstand kiezen voor het
				bos en de Zeepeduinen.
			</p>
			<p>
				<a class="button" href="mailto:info@deweie.nl"> Neem contact op </a>
			</p>
			<div class="contact">
				<span>
					<font-awesome-icon icon="far fa-envelope" size="1x" />
					<a href="mailto:info@deweie.nl">info@deweie.nl</a>
				</span>
				<span>
					<font-awesome-icon icon="fas fa-phone" size="1x" />
					<a href="tel:+31655128855">+31 6 55 12 88 55</a>
				</span>
			</div>
		</div>
		<div class="column">
			<div class="grid-50 images">
				<img src="~/assets/images/home-1-700.jpg" alt="Huisje" />
				<img src="~/assets/images/home-2-700.jpg" alt="Huisje" />
				<img src="~/assets/images/home-3-700.jpg" alt="Huisje" />
				<img src="~/assets/images/home-4-700.jpg" alt="Huisje" />
				<img src="~/assets/images/home-5-700.jpg" alt="Huisje" />
				<img src="~/assets/images/home-6-700.jpg" alt="Huisje" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IndexPage',
};
</script>

<style lang="scss" scoped>
.button {
	padding: 10px 30px;
	margin: 20px 0;
	display: inline-block;
	float: none;
	text-decoration: none;
	color: #000;
	background: transparent;
	border: 3px solid #000;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 16px;

	&:hover {
		background: #000;
		color: #fff;
	}
}

.contact {
	display: inline-flex;
	justify-content: center;
	gap: 15px;

	svg {
		font-size: 20px;
		margin: 0 7px 0 0;
		color: #000;

		&.fa-phone {
			font-size: 18px;
		}
	}
}

.images {
	margin-top: 50px;
	gap: 40px;
}

@media (max-width: 1080px) {
	.column {
		padding: 0;
	}

	.column p {
		margin: 15px 0 30px;
	}
}

@media (max-width: 700px) {
	.contact {
		flex-wrap: wrap;
	}

	.images {
		grid-template-columns: 1fr;
	}
}
</style>
